import * as React from 'react';
import * as ReactDOM from 'react-dom';

/*
 * Auto-mounts react components rendered as HTML from rails.
 */
export default (availableComponents) => {
  const elements = document.querySelectorAll('[data-react-class]');
  [].forEach.call(elements, function(element) {
    const className = element.getAttribute('data-react-class');
    const propsJson = element.getAttribute('data-react-props');
    let props = propsJson && JSON.parse(propsJson);
    if(availableComponents[className] === undefined) {
      console.warn("Trying to mount a React component with name " + className 
          + " but no such class is mounted.");
      return;
    }

    const component = React.createElement(availableComponents[className], props);
    ReactDOM.render(component, element);
  });
}
