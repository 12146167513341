import * as React from 'react';

import { Marker, Polyline, InfoWindow } from "react-google-maps"

import Icons from './MapIcons';

import axios from 'axios';


class MarkerInfo extends React.Component {
  state = {
    remoteHTML: null,
    isLoading: false,
  }

  componentDidMount() {
    const { marker } = this.props;

    if(marker.remote) {
      this.setState({
        isLoading: true,
      });
      axios.get(`/backroom/map/marker/${marker.id}`)
        .then(response => {
          this.setState({
            remoteHTML: response.data,
            isLoading: false,
          })
        });
    }
  }

  render() {
    const { marker } = this.props;
    return (
      <InfoWindow>
        <div>
        {this.state.isLoading && <h2>Loading...</h2>}
        {this.state.remoteHTML ?  
            <div dangerouslySetInnerHTML={{__html: this.state.remoteHTML}}></div> 
            : <div>
                <a href={marker.url}>{marker.label}</a>
            { marker.note && <div><small>{marker.note}</small></div> }
              </div>
        } 
        </div>
      </InfoWindow>
    )
  }
}

export default class MapLayer extends React.Component {
  state = {
    currentMarker: {},
  }

  onMarkerClick = (marker) => {
    this.setState({
      currentMarker: marker,
    });
  }

  isGroupVisible = (group) => {
    if(this.props.visible_groups.length == 0) return true;
    return this.props.visible_groups.indexOf(group) != -1;
  }

  render() {
    if(!this.props.visible || !this.props.data) return null;
    const { map, google, mapCenter } = this.props;
    //const passThru = {map, google, mapCenter};
    return (
      <div>
        {this.props.data.markers.map( marker => 
          <Marker position={{lat: marker.lat, lng: marker.lng}} 
            key={marker.id}
            title={marker.label}
            visible={this.isGroupVisible(marker.group)}
            icon={`/static/maps/${marker.icon}.svg`}
            zIndex={this.props.on_top ? 10000 : null}
            onClick={() => this.onMarkerClick(marker)}>
              {marker == this.state.currentMarker && 
                <MarkerInfo marker={marker} />}
            </Marker>
        )}
        {this.props.data.geometry.map( geo => 
          <Polyline path={geo.path} 
            visible={this.isGroupVisible(geo.group)}
            strokeColor="#0000FF"
            strokeOpacity={0.8}
            strokeWeight={1} />
        )}
      </div>
    )
  }
}
