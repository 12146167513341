import { Controller } from "stimulus"

let timeoutId = null;
const debounce = (callback, wait) => {
  return (...args) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback.apply(null, args);
    }, wait);
  };
}

let outsideClickListener = null;

export default class extends Controller {
  static targets = ['results'];
  static values = {url: String}

  showResults() {
    this.resultsTarget.classList.remove('d-none');

    outsideClickListener = document.addEventListener('click', (event) => {
      if(!this.resultsTarget.contains(event.target)) {
        this.hideResults();
        document.removeEventListener('click', outsideClickListener);
      }
    });
  }

  hideResults() {
    window.clearTimeout(timeoutId);
    this.resultsTarget.classList.add('d-none');
  }

  findResults(event) {
    if (event.target.value.length > 2) {
      debounce( () => {
        this.resultsTarget.src = this.urlValue.replace(/%s/g, encodeURIComponent(event.target.value))
        this.showResults();
      },500)();
    } else {
      this.hideResults();
    }
  }
}
