import { Controller } from "stimulus"

export default class extends Controller {
  open() {
    console.log("Will open...", this.element);
    [].forEach.call(this.element.querySelectorAll(".js-collapsable"), el => {
      el.classList.add('collapse');
    });
    [].forEach.call(event.currentTarget.parentNode.querySelectorAll('.js-collapsable'),
      el => { el.classList.toggle('collapse') } );

    
  }
}
