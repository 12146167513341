import { Application } from "stimulus"

import DependentElementController from "./dependent_element_controller"
import HovercardController from "./hovercard_controller"
import AccordionController from "./accordion_controller"
import ApplicantsBulkController from "./applicants_bulk_controller"
import InstantSearchController from "./instant_search_controller"
import RichTextController from "./rich_text_controller"

const application = Application.start()
application.register("dependent-element", DependentElementController)
application.register("hovercard", HovercardController)
application.register("accordion", AccordionController)
application.register("applicants-bulk", ApplicantsBulkController)
application.register("instant-search", InstantSearchController)
application.register("rich-text", RichTextController)
console.log("Stimulus init...")
//
// import Flatpickr
import Flatpickr from 'stimulus-flatpickr'

// Import style for flatpickr
require("flatpickr/dist/flatpickr.css")

// Manually register Flatpickr as a stimulus controller
application.register('flatpickr', Flatpickr)
