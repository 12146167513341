import { Controller } from 'stimulus'; 

export default class extends Controller {
  static targets = ['control', 'child'];

  connect() {
    this.handleChange();
  }
  handleChange() {
    const val = this.controlTarget.value;

    const visibleValue = this.data.get('value');
    console.log(visibleValue);
    if(val == visibleValue) {
      this.childTarget.style.display = 'block';
    } else {
      this.childTarget.style.display = 'none';
    }
  }
}
