import * as React from 'react';
import axios from 'axios';

import './styles';

import MapContainer from './MapContainer';

/*
 * Layer = {
 *   name: string,
 *   label: 
 *   visible: whether it's shown
 *   filter: [] an array of the groups to show
 *   data: {
 *     markers: [],
 *     geometry: [],
 *   }
 * }
 */ 
import MapLayer from './MapLayer';

import { ListGroup, ListGroupItem } from 'reactstrap';
import { Button, ButtonGroup } from 'reactstrap';

const StyleControl = ({style, onChange}) => (
    <div className="p-2 text-center" size="sm">
    <ButtonGroup>
      <Button 
        outline={ style != 'default' }
        color="primary"
        onClick={() => onChange('default')}>
      Light
    </Button>
      <Button 
        outline={ style != 'dark' }
        color="primary"
        onClick={ () => onChange('dark') }>
        Dark
      </Button>
    </ButtonGroup>
  </div>
)

class LayersPanel extends React.Component {
  state = {
    isOpen: true,
  }

  handleToggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  render() {
    const { isOpen } = this.state;
    if(!isOpen) {
      return (
        <div className="Panel">
          <Button className="PanelTrigger" 
            onClick={this.handleToggle} size="sm">Layers &gt;</Button>
        </div>
      );
    }

    
    return (
      <div className="Panel"> 
        <div className="PanelContent">
          <div className="text-right m-2">
            <Button onClick={this.handleToggle} size="sm">&lt; Hide</Button>
          </div>
          {this.props.children}
        </div>
      </div>
    );
  }
}

// Props: groups[]
class GroupFilter extends React.Component {
  handleChange = (group, visible) => {
    this.props.onChange({
      visible_groups: [
        ...this.props.selected.filter( g => g != group ),
        (visible ? group : null)
      ].filter( el => el != null ),
    })
  }
    
  render() {
    const { groups, selected, onChange } = this.props;
    if(groups.length == 0) return null;

    return (
      <ListGroup className="GroupFilter">
        {groups.map( group => {
          return (
            <ListGroupItem key={group}>
              <VisibilityIndicator 
                visible={selected.indexOf(group) != -1} 
                onChange={(e) => this.handleChange(group, e.target.checked)} />
              {group || 'None'}
            </ListGroupItem>
          );
        })}     
      </ListGroup>
    )
  }
}

const VisibilityIndicator = ({visible, onChange}) => {
  return (
    <input type="checkbox" checked={visible} onChange={onChange} 
      style={{marginRight: 10}} />
  )
}



const LayerControl = ({layer, onChange}) => {
  if(!layer.data) return (
    <ListGroupItem>
      Loading...
    </ListGroupItem>
  );

  let groups = [];
  layer.data.markers.map( marker => {
    if(groups.indexOf(marker.group) == -1) {
      groups.push(marker.group);
    }
  })
  // If there's only one group, don't bother offering filter
  if(groups.length == 1) { groups = [] }
  groups = groups.sort();

  return (
    <ListGroupItem>
      <label>
        <VisibilityIndicator visible={layer.visible} onChange={() => onChange({visible: !layer.visible})} />
          {layer.label}
        </label>
        { layer.visible && <GroupFilter groups={groups} selected={layer.visible_groups} 
          onChange={onChange} />}
    </ListGroupItem>
  );
}

class SuperMap extends React.Component {
  state = {
    layers: [],
    showingInfoWindow: false,
    activeMarker: {},
  }

  map = null;

  static defaultProps = {
    mapStyle: 'default',
  }
    
  constructor(props) {
    super(props);

    this.state.layers = props.layers;
    this.state.mapStyle = props.mapStyle;
  }

  fetchLayer = (name, { bounds } ) => {
    axios.get('/backroom/map/layer', { params: {
      layer: name,
    }})
      .then(response => {
        const newLayers = this.state.layers.map( layer => {
          if(layer.name != name) return layer;

          // If the layer needs refreshing, set it to auto-update
          if(layer.refresh) {
            setTimeout(() => {
              this.fetchLayer(layer.name, { bounds });
            },layer.refresh * 1000)
          }

          return (
            { visible_groups: [], ...layer, data: response.data }
          );
        })
        this.setState({
          layers: newLayers,
        })
      });
  }

  handleChangeLayer = (layerName, newValues) => {
    this.setState({
      layers: this.state.layers.map( layer => {
        if(layer.name != layerName) return layer;
        return {
          ...layer,
          ...newValues,
        }
      })
    });
  }

  handleMapMounted = (ref) => {
    this.map = ref;
    this.props.layers.map( layer => {
      this.fetchLayer(layer.name, { bounds: this.map.bounds } );
    })
  }

  handleMarkerClick = (props, marker, e) => {
    return;
    this.setState({
      activeMarker: marker,
      showingInfoWindow: true
    });
  }

  handleChangeStyle = (newStyle) => {
    this.setState({
      mapStyle: newStyle,
    })
  }
  
  render() {
    console.log("Rendering SuperMap...")
    return (
      <div className="SuperMap">
        <LayersPanel>
          {this.state.layers.map( layer => 
            <LayerControl 
              layer={layer} 
              key={layer.name}
              onChange={ newValues => 
                this.handleChangeLayer(layer.name, newValues)  
              } />
           )}
            <StyleControl style={this.state.mapStyle} onChange={this.handleChangeStyle} />
        </LayersPanel>
        <MapContainer layers={this.state.layers} onMounted={this.handleMapMounted} mapStyle={this.state.mapStyle} />
      </div>
    );
  }
};

export default SuperMap;
