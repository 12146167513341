import * as React from 'react';
import axios from 'axios';

type ShopSelectProps = {
  value: number,
  inputName: string,
  clientId?: number,
}

class ShopSelect extends React.Component<ShopSelectProps> {
  state = {
    shops: [],
    value: null,
    isLoading: false,
  }

  constructor(props) {
    super(props);

    this.state.value =  props.value;
  }

  componentDidMount() {
    this.setState({
      isLoading: true,
    })
    let url = (window as any).API_BASE+'/shops';
    if(this.props.clientId) {
      url = url + `?client_id=${this.props.clientId}`;
    }
    axios.get(url)
      .then((response) => {
        this.setState({
          shops: response.data.data,
          isLoading: false,
        });

        if(this.props.value != null) {
          const shop = this.state.shops.find( s => s.id == this.props.value )
          shop && (window as any).updateShop(shop.id);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  handleChange = (e) => { 
    const shop = this.state.shops.find( s => s.id == e.target.value );
    (window as any).updateShop(shop.id);
    this.setState({
      value: shop.id,
    })
    console.log(shop.id);
  }

  render() {
    let { inputName } = this.props;
    let { value } = this.state;
    const { isLoading, shops } = this.state;

    if(isLoading) return <p>Loading shops...</p>;

    if(value == null) { value = '' }

    return (
      <div className="form-group">
        <select 
          className="form-control"
          value={value}
          name={inputName}
          onChange={this.handleChange}>

            <option value="">Select store</option>
                {shops.map( shop => (
                  <option value={shop.id} key={shop.id}>
                    {this.props.clientId ? shop.name : shop.admin_name}
                  </option>      
          ))}
        </select>
      </div>  
    );
  }
}

export default ShopSelect;
