import * as React from 'react';
import axios from 'axios';
import * as moment from 'moment';
import Button from 'react-bootstrap/Button';

import EmployeeSelect from '../components/EmployeeSelect';



type RotaAppProps = {
  team: {
    id: number,
    name: string,
  },
  dates: {
    date: string,
    employee_ids: number[],
    comment_count: number,
  }[],
}

class RotaApp extends React.Component<RotaAppProps> {
  state = { 
    dates: this.props.dates, 
    employees: [], 
    isLoading: true,
  };

  hasChanged = () => {
    (window as any).hasChanges = true; // initially, it is saved (no action has been done)

    window.onbeforeunload = confirmExit;
    function confirmExit() {
      if ((window as any).hasChanges) {
          return "You did not save, do you want to do it now?";
      }
    }
  }

  componentWillMount() {
    axios.get('/api/v0/employees').then( response => {
      this.setState({
        employees: response.data.data,
        isLoading: false,
      })
    });
  }

  handleChangeDate = (date, values) => {
    this.hasChanged();
    this.setState({
      dates: this.state.dates.map( el => {
        if(el.date != date ) return el;
        return { ...el, ...values }
      })
    })
  }

  handleSubmit = (e) => {
    e.target.classList.add('disabled');
    e.target.innerHTML = 'Saving...';
    const target = e.target;

    axios.put(`/backroom/rotas/${this.props.team.id}`, { 
      data: this.state.dates,
    }).then( response =>  {
      alert("Rota saved.");
      (window as any).hasChanges = false;
      target.classList.remove('disabled'); 
      target.innerHTML = 'Save';
    });
  }

  // Copies the employees of the previous date into this one.
  fillWithPrevious = (date) => {
    const prevDate = moment(date).subtract(1, 'days').format("YYYY-MM-DD");
    const employeeIds = this.state.dates.find( el => el.date == prevDate ).employee_ids;
    this.handleChangeDate(date, { employee_ids: employeeIds });
  }

  renderNotesIcon(count) {
    if(parseInt(count) == 0) return null;
    return <span>{count} <span className="fas fa-comments"></span></span>;
  }

  render() {
    const { dates, employees, isLoading } = this.state;
    const { team } = this.props;


    if(isLoading) {
      return <p>Loading...</p>;
    }

    return (
      <div>
      <table className="table">
      <tbody>
        {dates.map( (date,i) => {
          const formattedDate = moment(date.date).format('ddd D MMM')
          const url = date.slot_id ? `/backroom/slots/${date.slot_id}` : null;
          return (
            <tr key={date.date}>
            <td style={{ width: 120 }}>
                <a href={url} target="_blank">
                <div>
                  {formattedDate}
                </div>
                {this.renderNotesIcon(date.note_count)}
                </a>
            </td>
              <td>
                <EmployeeSelect name={`employee_ids[${date.date}]`}  
                  employees={employees}
                  onChange={ selectedEmployeeIds => { 
                    this.handleChangeDate(date.date, { employee_ids: selectedEmployeeIds })
                  } }
                  placeholder=""
                  value={date.employee_ids} />
              </td>
              <td style={{width: 100}}>
                {i > 0 &&
                  <Button tabIndex={-1} onClick={ e => this.fillWithPrevious(date.date)}>From above</Button> }
              </td>
            </tr>
          );
        })}
      </tbody>
      </table>
        <Button onClick={this.handleSubmit}>Save</Button>
      </div>
    )
  }
}

export default RotaApp;
