import * as React from 'react';
import Select from 'react-select';

type EmployeeSelectProps = {
  name: string,
  value: any,
  employees: {
    name: string,
    id: number
  }[],
  onChange: Function,
  placeholder?: string,
}

class EmployeeSelect extends React.Component<EmployeeSelectProps> {
  handleChange = (newValue) => {
    if(newValue == null) this.props.onChange([]);
    this.props.onChange(newValue.map( el => el.value ).sort())
  }
  render() {
    const styles = {
      multiValueLabel: (provided, state) => {
        return { ...provided,  fontWeight: 'bold'}; 
      }
    }

    const options = this.props.employees.map( employee => ({
      label: employee.name,
      value: employee.id,
    }));

    const values = this.props.value.map( employee_id => {
      const employee = this.props.employees.find( el => el.id == employee_id )
      if(employee) {
        return (
          {
            label: employee.name,
            value: employee.id
          }
        )
      } else {
        return (
          {
            label: 'Deleted employee',
            value: employee_id,
          }
        )
      }
    });
    return (
      <Select options={options} 
        styles={styles}
        placeholder={this.props.placeholder}
        onChange={this.handleChange}
        isMulti={true} 
        value={values} />
    );
  }
}

export default EmployeeSelect;
