import { Controller } from "stimulus";
import { createPopper } from '@popperjs/core';
import '../../stylesheets/hovercard';


export default class extends Controller {
  static targets = ["card"];
  static values = { url: String };

  show() {

    if (this.hasCardTarget) {
      this.cardTarget.classList.remove("d-none");
    } else {
      fetch(this.urlValue)
        .then((r) => r.text())
        .then((html) => {
          const fragment = document
            .createRange()
            .createContextualFragment(html);

          this.element.appendChild(fragment);
          createPopper(this.element, this.cardTarget);
          //alert("Added")
        });
    }
  }

  hide() {
    console.log(this.cardTarget);
    if (this.hasCardTarget) {
      this.cardTarget.classList.add("d-none");
    }
  }

  disconnect() {
    if (this.hasCardTarget) {
      this.cardTarget.remove();
    }
  }
}
