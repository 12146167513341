import * as bootstrap from "bootstrap"

import RotaApp from '../RotaApp';
import ShopSelect from '../components/ShopSelect';
import SuperMap from '../apps/SuperMap';

import '../stylesheets/base/theme/modern';
import '../stylesheets/admin/modules/shuffle';
import '../stylesheets/admin/modules/invoices';


import mountComponents from '../utilities/mounter';

// Graphs
require("chartkick")
require("chart.js")
// will be part of Chartkick.js 4.0
window.dispatchEvent(new Event("chartkick:load"));

// will be part of Chartkick.js 4.0
Chartkick.destroyAll = function() {
  for (var id in Chartkick.charts) {
    Chartkick.charts[id].destroy();
    delete Chartkick.charts[id];
  }
}

// may be part of Chartkick.js 4.0 or may leave it to apps to add
document.addEventListener("turbo:before-render", function() {
  Chartkick.destroyAll();
});

document.addEventListener("turbo:load", function() {
  console.log("Turbo loaded")
  mountComponents({
    RotaApp, 
    ShopSelect,
    SuperMap,
  });
})

import '../stimulus/admin';
