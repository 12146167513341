import React from "react"
import { compose, withProps } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

import mapStyles from './map_styles';
import MapLayer from './MapLayer';



const MyMapComponent = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${window.GOOGLE_MAPS_KEY}`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100vh` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) =>
  <GoogleMap
    ref={props.onMounted}
    defaultZoom={8}
    options={{ styles: mapStyles[props.mapStyle] }}
    defaultCenter={{ lat: 54, lng: 0 }} >
  {props.layers.map( layer  => <MapLayer key={layer.name} {...layer}></MapLayer> )}
  </GoogleMap>
)

class MapContainer extends React.PureComponent {
  static defaultProps = {
    mapStyle: 'default',
  }

  render() {
    return (
      <MyMapComponent 
        layers={this.props.layers} 
        mapStyle={this.props.mapStyle}
        onMounted={this.props.onMounted} />
    )
  }
}

export default MapContainer;
