import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    window.CodeMirror.fromTextArea(this.element, {
      mode: 'htmlmixed',
      lineNumbers: true,
      lineWrapping: true,
    });
  }
}
